const MeshSourceBuffer = require('./mesh_source_buffer');
const dash = require('@8i/dashjs');
const v_standard  = require('../../shaders/v_standard.glsl').default
const f_standard = require('../../shaders/f_standard.glsl').default

const MAX_BONE_TRANSFORMS = 25;

// The iOS HLS player requires that video element be added to DOM
// to prevent their energy-saving logic from suspending playback
function createVideoElement(parentEl) {
    const videoEl = document.createElement("video")
    // hide element off-screen and above 
    videoEl.style.position = "fixed"
    videoEl.style.top = "-9999em"
    videoEl.style.left = "-9999em"
    videoEl.style.zIndex = "-9999"
    parentEl.append(videoEl)
    return videoEl
}

class VolcapDashActor {
    constructor(src, renderer, scene, camera, opts = {}) {
        let { autoplay = false, fps = -1, muted = true, loop = false, abr = true, initialQualityRatio = 0 } = opts;
        this._enableHeadTracking = opts['head-tracking'];
        this._initialized = false;
        this._ready = false;
        this._src = src;
        this._video = createVideoElement(document.body)
        this._video.muted = muted;
        this._video.loop = loop;
        this._fps = fps;
        this._camera = camera;
        this._bufferHealthy = {
            audio: false,
            mesh: false,
            video: false,
        }
        this._video.onresize = () => {
            const width = this._video.videoWidth;
            const height = this._video.videoHeight;
        }
        this._video.setMediaKeys = (mk) => {
            // We have to swallow the MediaKey setter or the EME system will
            // try to decrypt the unencrypted video...
            return Promise.resolve();
        };
        this._video.onended = (evt) => {
            if (this.onended) {
                this.onended(evt);
            }
        }
        this._video.onplay = (evt) => {
            if (this.onplay) {
                this.onplay(evt);
            }
        }
        this._video.onpause = (evt) => {
            if (this.onpause) {
                this.onpause(evt);
            }
        }

        this._seeking = false;
        this._video.onseeked = () => {
            this._seeking = false;
            this._lastFrame = -1;
        }

        this._video.onloadeddata = (e) => {
            if (this.onloadeddata) {
                this.onloadeddata(e);
            }
        }

        // We support two different rendering strategies:
        // 1. if the requestVideoFrameCallback API is supported (Chrome only),
        //    we prefer this as it will reduce the frequency of our texture
        //    fetching to match the video framerate.
        // 2. otherwise we fall back to being driven by the browser render rate,
        //    aka the requestAnimationFrame cadence, which may be higher than
        //    the video framerate by 2x in most cases.
        if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
            const handleVideoFrame = (now, { mediaTime }) => {
                // Do something with the frame.
                const duration = this._video.duration;
                this._updateVideo(mediaTime, duration)
                // Re-register the callback to be notified about the next frame.
                this._video.requestVideoFrameCallback(handleVideoFrame);
            };
            this._video.requestVideoFrameCallback(handleVideoFrame);
        }

        this._lastFrame = -1;

        this._gl = renderer.getContext();

        const gl = this._gl;
        this._stagingTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this._stagingTexture);
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 2048, 2048, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);

        this._framebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this._stagingTexture, 0);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        this._renderTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this._renderTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 2048, 2048, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.bindTexture(gl.TEXTURE_2D, null);

        // This represents the latest texture that we want to render
        this._texture = new THREE.Texture();
        this._texture.minFilter = THREE.LinearFilter;
        this._texture.magFilter = THREE.LinearFilter;
        this._texture.format = THREE.RGBFormat;
        this._texture.anisotropy = 10;

        // override the three.js texture with this
        const texProps = renderer.properties.get(this._texture);
        texProps.__webglTexture = this._renderTexture;

        const boneTransforms = new Array(MAX_BONE_TRANSFORMS);
        boneTransforms.fill(new THREE.Matrix4());
        // And here is the material and the mesh, initially lacking geometry
        let material = new THREE.ShaderMaterial({
            glslVersion: THREE.GLSL1,
            defaultAttributeValues: {
                'weightsAtt': [0, 0, 0, 0],
                'bindingsAtt': [0, 0, 0, 0],
            },
            uniforms: {
                map: new THREE.Uniform(this._texture),
                boneTransforms: new THREE.Uniform(boneTransforms),
            },
            vertexShader: v_standard,
            fragmentShader: f_standard,
            transparent: true,
        });
        material.side = THREE.DoubleSide;
        this._mesh = new THREE.Mesh(undefined, material);
        this._mesh.castShadow = true;
        this._mesh.frustumCulled = false;
        this._mesh.receiveShadow = false;
        if (scene)
            scene.add(this._mesh);

        const primer_src = 'https://assets.8i.com/fallback.mp4'; // Universal fallback primer

        this._availablilityStartTime = undefined;

        MeshSourceBuffer.initialize(primer_src, (isFallback) => {
            this._player = dashjs.MediaPlayer().create();
            this._player.initialize(this._video, this._src, autoplay);
            this._player.setProtectionData({
                "org.w3.clearkey": {
                    "serverURL": "https://q0qseysdoh.execute-api.us-east-2.amazonaws.com/v1/license"
                }
            });

            let filterCapabilities = (representation) => {
                if ('framerate' in representation && this._fps !== -1) {
                    return representation.framerate === `${this._fps}/1`;
                }
                return true;
            }
            this._player.registerCustomCapabilitiesFilter(filterCapabilities);

            this._player.on(dashjs.MediaPlayer.events.MANIFEST_LOADED, ({ data: manifest }) => {
                const manifestUrl = manifest.originalUrl;
                this._video.onloadedmetadata = (e) => {
                    if (!this._initialized) {
                        if (isFallback) {
                            this._video.crossOrigin = "anonymous";
                            // without the following, iPhone will refuse to autoplay
                            this._video.setAttribute('playsinline', 'playsinline');
                            const hls_src = manifestUrl.replace('manifest.mpd', `master_${this._fps}.m3u8`);
                            const mp4_src = manifestUrl.replace('manifest.mpd', `fallback.mp4`);
                            fetch(hls_src)
                                .then((res) => {
                                    if (res.ok && this._video.canPlayType('application/vnd.apple.mpegURL') !== '') {
                                        this._video.src = hls_src;
                                    } else {
                                        this._video.src = mp4_src;
                                    }
                                    this._video.load();
                                    if (autoplay) {
                                        this._video.play()
                                            .catch(e => console.error('failed to play fallback', e))
                                    }
                                });
                        }
                        this._initialized = true;
                    }
                }
                // Identify possible framerate values
                let framerates = Array.from(new Set(
                    manifest.Period_asArray[0].AdaptationSet_asArray
                        .find((as) => as.contentType === 'video')
                        .Representation_asArray
                        .map((rep) => rep.framerate)))
                    .filter((fps) => fps !== undefined);
                if (framerates.length) {
                    let lowest = framerates
                        .reduce((acc, mfps) => {
                            let fps_num = mfps;
                            if (typeof mfps === 'string') {
                                let res = mfps.match(/(?<fps>\d+)\/1/);
                                if (res.groups && res.groups.fps) fps_num = parseInt(res.groups.fps);
                            }
                            return Math.min(fps_num, acc);
                        }, Number.MAX_VALUE);
                    // This will cover both cases:
                    // 1. automatic FPS
                    // 2. a selected FPS does not exist
                    if (isFallback || framerates.includes(`${this._fps}/1`) === false) this._fps = lowest;
                } else {
                    this._fps = 15; // TODO get framerates into live manifest
                }
            });

            this._player.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, ({ liveStartTime, streamInfo }) => {
                // console.log(this._player.getBitrateInfoListFor('video'));
                this._availablilityStartTime = streamInfo.manifestInfo.availableFrom;
            });

            this._player.on(dashjs.MediaPlayer.events.BUFFER_EMPTY, ({ mediaType }) => {
                this._bufferHealthy[mediaType] = false;
                if (this.onbufferempty) {
                    this.onbufferempty();
                }
            });

            let meshLoaded = new Promise((resolve, reject) => {
                this._player.on(dashjs.MediaPlayer.events.BUFFER_LOADED, ({ mediaType }) => {
                    if (mediaType === 'mesh') resolve();
                })
            });
            let audioVideoLoaded = new Promise((resolve, reject) => {
                this._player.on(dashjs.MediaPlayer.events.CAN_PLAY, () => {
                    resolve();
                });
            })
            Promise.all([meshLoaded, audioVideoLoaded]).then(() => {
                if (this.oncanplay) {
                    this.oncanplay();
                }
            });
            this._player.on(dashjs.MediaPlayer.events.BUFFER_LOADED, ({ mediaType }) => {
                this._bufferHealthy[mediaType] = true;
                if (this._bufferHealthy['mesh']
                    && this.onbufferloaded) {
                    this.onbufferloaded();
                }
            });

            this._player.updateSettings({
                debug: {
                    logLevel: dashjs.Debug.LOG_LEVEL_WARNING
                },
                streaming: {
                    bufferPruningInterval: 10,
                    bufferToKeep: 10,
                    bufferTimeAtTopQuality: 10,
                    bufferTimeAtTopQualityLongForm: 10,
                    fastSwitchEnabled: false,
                    abr: {
                        bandwidthSafetyFactor: 0.9,
                        useDefaultABRRules: true,
                        useBufferOccupancyABR: true,
                        useDeadTimeLatency: true,
                        limitBitrateByPortal: false,
                        usePixelRatioInLimitBitrateByPortal: false,
                        maxBitrate: { audio: -1, video: -1, mesh: -1 },
                        minBitrate: { audio: -1, video: -1, mesh: -1 },
                        maxRepresentationRatio: { audio: -1, video: 1, mesh: 1 },
                        initialRepresentationRatio: { audio: -1, video: initialQualityRatio, mesh: 0 },
                        autoSwitchBitrate: { audio: false, video: abr, mesh: false },
                    },
                    trackSwitchMode: {
                        audio: 'alwaysReplace',
                        video: 'alwaysReplace',
                        mesh: 'alwaysReplace'
                    },
                    retryIntervals: {
                        MPD: 1000,
                        XLinkExpansion: 500,
                        MediaSegment: 500,
                        InitializationSegment: 500,
                        BitstreamSwitchingSegment: 1000,
                        IndexSegment: 1000,
                        other: 1000
                    },
                    retryAttempts: {
                        MPD: 1000,
                        XLinkExpansion: 1,
                        MediaSegment: 2,
                        InitializationSegment: 2,
                        BitstreamSwitchingSegment: 3,
                        IndexSegment: 3,
                        other: 3
                    },
                }
            });

            this._player.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_REQUESTED, (e) => {
                // console.log('quality change requested', e);
            });
            this._player.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, (e) => {
                // console.log('quality change rendered', e);
            });

            // this._video.onstalled = (e) => {
            //     console.error('stalled', e);
            // }
            // this._video.onsuspend = (e) => {
            //     console.error('suspend', e);
            // }
            // this._video.onerror = (e) => {
            //     console.error('error', e);
            // }
            // this._video.onabort = (e) => {
            //     console.error('abort', e);
            // }

            this._ready = true;
        }, (sourceBuffer) => {
            this._meshSourceBuffer = sourceBuffer;
            this._player.registerLicenseResponseFilter((resp) => new Promise((resolve, reject) => {
                for (let key of resp.data.keys) {
                    window.crypto.subtle.importKey(
                        "jwk",
                        {
                            kty: key.kty,
                            k: key.k.replace(/=/g, ''),
                            alg: "A128CTR",
                            ext: true,
                        },
                        {
                            name: "AES-CTR"
                        },
                        false,
                        ["decrypt"]
                    ).then((key) => {
                        this._meshSourceBuffer.setKey(key);
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    });
                }
            }))
        });
    }

    destroy(scene) {
        if (scene)
            scene.remove(this._mesh);
        MeshSourceBuffer.destroy();
        this._meshSourceBuffer = undefined;
        this._video.pause();
        this._video = null;
        this._player.destroy();
        this._player = null;
    }

    play() {
        this._video.play().catch((e) => console.log(e));
    }

    pause() {
        this._video.pause();
    }

    seek(time) {
        this._seeking = true;
        this._video.currentTime = time;
    }

    set muted(muted) {
        this._video.muted = muted;
    }

    get muted() {
        return this._video.muted;
    }

    set loop(loop) {
        this._video.loop = loop;
    }

    get currentTime() {
        return this._video.currentTime;
    }

    set currentTime(time) {
        this._seeking = true;
        this._video.currentTime = time;
    }

    get duration() {
        return this._video.duration;
    }

    get paused() {
        return this._video.paused;
    }

    get ready() {
        return this._ready;
    }

    _getFrameDetails(widthHint, heightHint) {
        // Size of each pixel
        const threshold = 680;
        const pixelStride = 4;
        const digitWidth = 16;
        const digitHeight = 1;
        const maxWidth = 2048;
        const maxHeight = 2080;
        const numBits = 20;
        const x = 0;
        const y = 0;
        const data = new Uint8Array(maxWidth * digitHeight * 4);
        const gl = this._gl;
        gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
        if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) == gl.FRAMEBUFFER_COMPLETE) {
            gl.readPixels(x, y, maxWidth, digitHeight, gl.RGBA, gl.UNSIGNED_BYTE, data);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);

        // First we need to determine how wide this image is.
        // We do this because the HTMLVideoElement videoWidth and videoHeight
        // properties are inaccurate for a few frames when a resolution change
        // occurs.  This is very painful and makes me cry.
        //
        // The alpha values outside of the image bounds will be 0, so we can
        // simply test a few pixels at the edge of possible image widths to
        // determine what the actual width is.
        let testPixel = (idx) => {
            return data[idx * pixelStride + 3] === 255;
        }

        let width = widthHint;
        let height = heightHint;
        // we can use the supplied video width and height as hints.
        if (testPixel(widthHint - 1) && !testPixel(widthHint)) {
            // the hint is probably correct.  this is the fast-path.
            width = widthHint;
            height = heightHint;
        } else {
            // the videoWidth and videoHeight values are not accurate,
            // so we must test pixels in the frame to determine the
            // exact size.
            for (width = maxWidth; width >= 0; width--) {
                if (testPixel(width)) {
                    width++;
                    break;
                }
                if (width == 0) {
                    return { width: -1, frameNum: -1 };
                }
            }

            const hdata = new Uint8Array(maxHeight * 4);
            gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
            if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) == gl.FRAMEBUFFER_COMPLETE) {
                gl.readPixels(x, y, 1, maxHeight, gl.RGBA, gl.UNSIGNED_BYTE, hdata);
            }
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);

            for (height = maxHeight; height >= 0; height--) {
                if (hdata[height * pixelStride + 3] === 255) {
                    height++;
                    break;
                }
                if (height == 0) {
                    return { width, height: -1, frameNum: -1 };
                }
            }
        }

        // Start from the right side, which is the least-significant binary
        // value, and move to the left 16px at a time to decode the number.
        const digitStride = digitWidth * pixelStride;
        const start = (width * pixelStride) - (digitStride / 2);
        let frameNum = -1;
        let totalSum = 0;
        let digits = [];
        for (let j = 0; j < numBits; j++) {
            let idx = start - j * digitStride;
            let r = data[idx];
            let g = data[idx + 1];
            let b = data[idx + 2];
            let sum = r + g + b;
            digits.push(sum);
            if (sum > threshold) {
                if (frameNum === -1) frameNum = 0;
                frameNum += 1 << j;
            }
            totalSum += sum;
        }
        // If we didn't positively identify a value based on white boxes, we
        // should look at the sum of all the boxes to see if it's mostly black
        // and then probably a zero.
        if (frameNum === -1 && totalSum < 30) frameNum = 0;
        return { width, height, frameNum, bits: numBits };
    }

    // This should be called by 
    update(elapsed, _currentTime, _duration) {

        if (this._seeking) return;

        if (!this._meshSourceBuffer) return;

        if (this._video.videoHeight == 0) return;

        if (this._video.readyState <= this._video.HAVE_METADATA) return;

        if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
            // We do nothing here, as the texture updates will be
            // driven by the requestVideoFrameCallback
        }
        else {
            let currentTime = this._video.currentTime;
            let duration = this._video.duration;
            this._updateVideo(currentTime, duration);
        }

        const {neckJoint, neckJointIndex, headJoint, headJointIndex} = this._mesh.geometry.userData;
        if (this._enableHeadTracking && neckJointIndex && neckJoint) {
            // Restrict Y rotation to +/- 30 Degrees
            const maxRotate = 45;
            const maxTilt = 15;
            let cameraSpherical = new THREE.Spherical();
            let dir = new THREE.Vector3();
            dir.subVectors( this._camera.position, new THREE.Vector3(headJoint.x / 100, headJoint.y / 100, headJoint.z / 100) ).normalize();
            cameraSpherical.setFromVector3(dir);
            let theta = 180 * cameraSpherical.theta / Math.PI;
            let phi = 180 * (cameraSpherical.phi - Math.PI / 2) / Math.PI;
            if (theta > maxRotate) {
                theta -= 2 * (theta - maxRotate);
                if (theta < 0) theta = 0;
            } else if (theta < -maxRotate) {
                theta += 2 * -(theta + maxRotate);
                if (theta > 0) theta = 0;
            }
            phi = Math.min(Math.max(-maxTilt, phi), maxTilt);

            let rotateX = THREE.MathUtils.degToRad(phi);
            let rotateY = THREE.MathUtils.degToRad(theta);
            // start with identity matrix
            let neckTransform = new THREE.Matrix4().makeTranslation(neckJoint.x, neckJoint.y, neckJoint.z);
            neckTransform.multiply(new THREE.Matrix4().makeRotationY(rotateY / 2));
            neckTransform.multiply(new THREE.Matrix4().makeRotationX(rotateX / 2));
            neckTransform.multiply(new THREE.Matrix4().makeTranslation(-neckJoint.x, -neckJoint.y, -neckJoint.z));
            this._mesh.material.uniforms.boneTransforms.value[neckJointIndex] = neckTransform;
            if (headJointIndex && headJoint) {
                // start with identity matrix
                let headTransform = neckTransform;
                headTransform.multiply(new THREE.Matrix4().makeTranslation(headJoint.x, headJoint.y, headJoint.z));
                headTransform.multiply(new THREE.Matrix4().makeRotationY(rotateY / 2));
                headTransform.multiply(new THREE.Matrix4().makeRotationX(rotateX / 2));
                headTransform.multiply(new THREE.Matrix4().makeTranslation(-headJoint.x, -headJoint.y, -headJoint.z));
                this._mesh.material.uniforms.boneTransforms.value[headJointIndex] = headTransform;
            }
        }
    }

    _updateVideo(currentTime, duration) {
        const gl = this._gl;
        gl.bindTexture(gl.TEXTURE_2D, this._stagingTexture);
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, this._video);
        gl.bindTexture(gl.TEXTURE_2D, null);

        const { width, height, frameNum, bits } = this._getFrameDetails(this._video.videoWidth, this._video.videoHeight);

        if (frameNum > -1 && frameNum !== this._lastFrame) {
            // First try to match by frame index.  This should succeed for
            // 'live' outputs and future VOD where the frame ID is included
            // in the Draco metadata.
            let wrappedFrameNum = frameNum % Math.exp(bits);
            let geometry = this._meshSourceBuffer.getMeshByFrameId(wrappedFrameNum);
            if (geometry) {
                this._lastFrame = frameNum;
            }
            if (!geometry) {
                // If that fails, we can try to match based on the frame
                // timestamp.
                const { timescale, duration: frameDuration } = this._meshSourceBuffer.getTiming();
                let scaledTime = Math.round(currentTime * timescale);
                let alignedTime = Math.floor(scaledTime / frameDuration) * frameDuration;
                // Now we are going to try to adjust the "estimated" frame number
                // based on the frame number we pulled from the staging frame.
                let estimatedIdx = alignedTime / frameDuration;
                let bucket = Math.floor(estimatedIdx / 1024.0) * 1024;
                let correctedIdx = bucket + frameNum;
                if (correctedIdx > this._lastFrame || this._lastFrame == 1023) {
                    let correctedTime = correctedIdx * frameDuration;
                    geometry = this._meshSourceBuffer.getMesh(correctedTime);
                    if (geometry) {
                        this._lastFrame = correctedIdx;
                    }
                } else if (correctedIdx == this._lastFrame) {
                    return;
                }
            }
            if (geometry) {
                this._mesh.geometry.dispose();
                this._mesh.geometry = geometry;
                gl.bindTexture(gl.TEXTURE_2D, this._renderTexture);
                gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer);
                gl.copyTexImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 0, 0, width, height, 0);
                gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            }
        } else if (frameNum === this._lastFrame) {
            gl.bindTexture(gl.TEXTURE_2D, this._renderTexture);
        }
    }

    render(width, height, camera, position, hidden) {
        this._mesh.visible = !hidden;
        this._mesh.position.copy(position);
    }
}

module.exports = VolcapDashActor;
